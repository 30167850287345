// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apihost: 'https://ivory-coast-atom-server.revenuedev.org',
  apihostDomain : 'https://ivory-coast-atom-server.revenuedev.org',
  whitelistDomain: "ivory-coast-atom-server.revenuedev.org",
  blacklistDomain: "ivory-coast-atom-server.revenuedev.org",
  accessToken: 'atom_access_token'
};



//plat
// export const environment = {
//   production: false,
//   apihost: 'http://62.168.166.110:3001',
//   apihostDomain : 'http://62.168.166.110:3001',
//   whitelistDomain: "62.168.166.110:3001",
//   blacklistDomain: "62.168.166.110:3001/auth",
//   accessToken: 'atom_access_token'
// };

// test server
// production: false,
// apihost: 'http://atom-stg.revenuedev.org:3011',
// apihostDomain : 'http://atom-stg.revenuedev.org:3011',
// whitelistDomain: "atom-stg.revenuedev.org:3011",
// blacklistDomain: "atom-stg.revenuedev.org:3011/auth",
// accessToken: 'atom_access_token'


//http://atom-stg.revenuedev.org:3011
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
